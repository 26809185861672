.private-label {
  position: relative;

  &__container {
    padding: 0rem var(--side-paddings);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include container;
  }

  &__title {
  }

  &__content {
    margin-right: 60%;
    width: 44rem;
  }

  &__logo {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: 0.8;

    @media only screen and (min-width: 1440px) {
      right: var(--side-paddings);
    }

    @media only screen and (max-width: $bp-large) {
      width: 60rem;
      top: auto;
      bottom: -8rem; // so the panda sits at the bottom
      opacity: 0.3;
    }
    @media only screen and (max-width: $bp-small) {
      width: 60vw;
      opacity: 0.1;
      bottom: 0;
    }
  }
}
