.header-banner {
  position: relative;
  height: 100vh;
  display: grid;
  // padding-right: 8rem;
  justify-content: right;
  align-content: center;
  @media only screen and (max-width: $bp-medium) {
    justify-content: left;
  }
  @media only screen and (max-width: $bp-small) {
    padding-right: 4rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-image: url(../../images/temp-bg.jpg);
    background-size: cover;
    background-position: 70%;
    background-color: rgba(17, 17, 17, 0.6);
    background-blend-mode: overlay;
    filter: grayscale(80%);
    z-index: -11;
  }
  @media only screen and (max-width: $bp-medium) {
    &::before {
      background-position: 15%;
    }
  }

  &__heading {
    width: 400px;
    display: block;
    text-align: right;
    // margin-right: 8rem;
    margin-right: var(--side-paddings);
    -webkit-animation: slide-in-bottom--lg 4s 0.3s cubic-bezier(0.19, 1, 0.22, 1) both;
    animation: slide-in-bottom--lg 4s 0.3s cubic-bezier(0.19, 1, 0.22, 1) both;

    @media only screen and (max-width: $bp-medium) {
      text-align: left;
      margin-right: 0;
      margin-left: 8rem;
      margin-left: var(--side-paddings);
    }

    @media only screen and (max-width: $bp-small) {
      width: 40rem;
    }
    .heading-1 {
      margin-bottom: 2rem;
    }

    // .heading-3 {
    //   margin-left: 33%;
    //   line-height: 2.5rem;
    // }
  }
}
