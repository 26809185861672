@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Raleway:wght@300;400;500;700&display=swap");

html {
  font-family: "lato", sans-serif;
}

body {
  font-size: 18px;
  font-weight: 400;

  @media only screen and (max-width: $bp-small) {
    font-size: 16px;
  }
}

h1 {
  font-family: "raleway", sans-serif;
  font-size: 50px;
  color: #fff;

  @media only screen and (max-width: $bp-small) {
    font-size: 35px;
  }
}

h2 {
  font-family: "raleway", sans-serif;
  font-size: 45px;
  color: #141414;
  padding-bottom: 3rem;

  @media only screen and (max-width: $bp-small) {
    font-size: 30px;
  }
}

h3 {
  font-family: "raleway", sans-serif;
  font-size: 20px;
  color: #fff;
}

p {
  color: #4a4a4a;
  line-height: 1.7;
  font-weight: 500;

  &:not(:last-of-type) {
    margin-bottom: 1em;
  }
}

sup {
  line-height: 0;
}

.columned-content {
  column-count: 2;
  column-gap: 40px;
  @media only screen and (max-width: $bp-medium) {
    column-count: auto;
  }
}

.footer {
  font-size: 14px;
}

.navigation {
  font-size: 16px;

  @media only screen and (max-width: 900px) {
    font-size: 20px;
    text-align: center;
  }
}
