.gallery {
  display: grid;
  grid-template-columns: repeat(7, 15rem);
  grid-template-rows: repeat(4, 15rem);
  justify-content: center;
  gap: 1.5rem;

  &-img {
    width: 100%;
    height: 100%;
    // height: 20rem;
    object-fit: cover;
    object-position: 50% 50%;
  }

  &__item {
    width: 30rem;
    height: 45rem;
    @media only screen and (max-width: 700px) {
      // width: 10rem;
      height: 60rem;
    }
    /*
    &--1 {
      grid-row: 2 / span 2;
      grid-column: 1 / 2;
    }
    &--2 {
      grid-row: 1 / span 2;
      grid-column: 2 / 3;
    }
    &--3 {
      grid-row: 3 / span 2;
      grid-column: 2 / 3;
    }
    &--4 {
      grid-row: 2 / span 2;
      grid-column: 3 / 4;
    }
    &--5 {
      grid-row: 1 / span 2;
      grid-column: 4 / 5;
    }
    &--6 {
      grid-row: 3 / span 2;
      grid-column: 4 / 5;
    }
    &--7 {
      grid-row: 2 / span 2;
      grid-column: 5 / 6;
    }
    &--8 {
      grid-row: 1 / span 2;
      grid-column: 6 / 7;
    }
    &--9 {
      grid-row: 3 / span 2;
      grid-column: 6 / 7;
    }
    &--10 {
      grid-row: 2 / span 2;
      grid-column: 7 / 8;
    }
    */
  }
}

.slider2 {
  height: 50rem;
  @media only screen and (max-width: 500px) {
    // height: 60rem;
  }
}
