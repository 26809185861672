.navigation__white-Bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #fff;
  z-index: -1;
  transition: all 0.3s ease;

  &--scrolled {
    height: 10rem;
    box-shadow: 0 5px 5px rgba(#999, 0.3);
  }
}

.navigation {
  font-family: "raleway", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  display: grid;
  background-color: transparent;

  grid-template-columns: repeat(2, min-content);
  align-items: center;
  justify-content: space-between;
  // padding: 2rem 8rem;
  padding: 2rem var(--side-paddings);
  margin-top: 2rem;
  font-weight: 500;
  color: #fff;
  transition: all 0.5s ease-in-out;

  @media only screen and (max-width: 900px) {
    color: #000;
  }

  @media only screen and (max-width: $bp-small) {
    padding-right: 0;
    // padding: 0.5rem 4rem;
    padding: 0.5rem var(--side-paddings);
  }

  &--scrolled {
    margin-top: 0;
    color: #000;

    @media only screen and (max-width: $bp-small) {
      margin-top: 1rem;
    }
  }

  &__logo {
    width: 18rem;
    height: 6rem;
    background-image: url(../../images/katex-logo-white-cropped.png);
    background-size: 100% 100%;

    &--scrolled {
      background-image: url(../../images/katex-logo-black-cropped.png);
    }
  }

  &__menu {
    @media only screen and (max-width: 900px) {
      &::after {
        content: "";
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw; //200
        height: 100vh; //300
        background-color: #fff;
        z-index: -999;
        transform: translateY(-90%);
        opacity: 0;
        transition: 0.4s ease-in-out;
      }
      &--scrolled::after {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &-list {
      overflow: auto;
      list-style: none;
      display: grid;
      grid-template-columns: repeat(5, max-content);
      // -ms-overflow-style: none;
      // scrollbar-width: none;

      li:not(:last-child) {
        margin-right: 6rem;

        @media only screen and (max-width: 900px) {
          margin-right: 0;
        }
      }

      @media only screen and (max-width: 900px) {
        // display: block;
        overflow-y: scroll;
        grid-template-columns: auto;
        grid-template-rows: repeat(4, minmax(min-content, max-content));
        row-gap: 5rem;
        visibility: hidden;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 120%);
        opacity: 0;
        transition: transform 1s cubic-bezier(0.5, 1, 0.89, 1), opacity 0.7s ease-in-out;

        &.mobile-menu-active {
          visibility: visible;
          transform: translate(-50%, calc(-50% + 4rem));
          opacity: 1;
        }

        @media only screen and (max-height: 600px) {
          grid-template-rows: auto;
          grid-template-columns: repeat(4, max-content);
        }
      }
    }

    &-item {
      display: inline-block;
      position: relative;
      margin: 2rem 0rem 2rem 2rem;
      text-decoration: none;
      color: currentColor;
      // transition: color 0.4s;
      @media only screen and (max-width: 900px) {
        margin: 2rem;
      }

      &::after {
        transition: all 0.4s;
        transform-origin: center;
        content: "";
        display: block;
        position: absolute;
        bottom: -1rem;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: currentColor;
        transform: scaleX(0);
      }

      &:hover {
        color: #b3c743;

        &::after {
          // width: calc(100% - 4rem);
          // transform: scaleX(0.75);
          transform: scaleX(1);
        }
      }
    }
  }
}

/*
// for hover turn green effect
.navigation--green {
  &:hover {
    // color: #b3c743;
  }
}
*/

// for homemade scrollspy
.unselected {
  color: #c2c2c2;

  @media only screen and (max-width: 900px) {
    color: currentColor;
  }
}

.active {
  transition: all 0.5s;
  color: #b3c743;
}

// Hamburger

.hamburger {
  display: none;
  @media only screen and (max-width: 900px) {
    display: block;
  }

  &.is-active {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: #b3c743;
    }
  }
}

.hamburger-inner--scrolled {
  &,
  &::before,
  &::after {
    background-color: #141414;
  }
}
