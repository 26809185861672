.footer {
  background-color: #4a4a4a;
  padding: 10rem 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: $bp-small) {
    padding: 10rem 10rem;
  }

  &__content {
    color: #fff;
  }
}
