.experience {
  position: relative;
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    background-color: #4a4a4a;
    // width: 100vw;
    height: auto;
  }

  &__container {
    position: relative;
    margin: 0 auto;
    padding: 16rem var(--side-paddings);
    display: flex;
    // min-width: 0;
    // background-color: #4a4a4a;
    @include container;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $bp-medium) {
      flex-direction: column-reverse;
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media only screen and (max-width: $bp-medium) {
      padding-bottom: 10rem;
    }
  }

  .dec-line {
    margin-left: auto;
  }

  &__title {
    text-align: right;
    width: 50rem;
    @media only screen and (max-width: $bp-small) {
      width: 100%;
      text-align: left;
    }
  }

  &__content {
    line-height: 1.7;
    font-weight: 500;
    margin-right: 10%;
    width: 46rem;

    @media only screen and (max-width: $bp-largest) {
      //1230 org
      text-align: right;
      margin-right: 0;
    }

    @media only screen and (max-width: $bp-medium) {
      width: auto;
    }

    @media only screen and (max-width: $bp-small) {
      width: 100%;
      text-align: left;
    }
  }

  &__title,
  &__content {
    color: #fff;
  }

  &__brands {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 1fr);
    grid-auto-flow: column;
    column-gap: 8rem; //to separate row1 and row2
    margin-right: auto; // put brands to left
    @media only screen and (max-width: $bp-largest) {
      margin-right: 0;
    }

    @media only screen and (max-width: $bp-large) {
      align-items: center;
      row-gap: 8rem;
    }

    @media only screen and (max-width: $bp-medium) {
      width: 100%;
      grid-template-rows: auto;
      grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
      grid-auto-flow: row;
      justify-items: center;
      align-items: center;
      justify-content: center;
      column-gap: 0;
      row-gap: 5rem;
    }

    &-logo {
      width: 20rem;
      fill: #fff;

      @media only screen and (max-width: $bp-largest) {
        width: 17rem;
      }

      @media only screen and (max-width: $bp-large) {
        width: 15rem;
      }

      &--row2 {
        align-self: flex-end;
        padding-top: 3rem;
        @media only screen and (max-width: $bp-large) {
          align-self: center;
          padding-top: 0;
          padding-left: 0;
        }
      }
    }

    .River {
      padding-top: 8rem;
      @media only screen and (max-width: $bp-large) {
        padding-top: 0;
      }
    }

    .Burton {
      padding-top: 6rem;
      @media only screen and (max-width: $bp-large) {
        padding-top: 0;
      }
    }
  }
}

:lang(zh) {
  .experience {
    &__content {
      @media only screen and (max-width: $bp-largest) {
        width: 50rem;
      }
      @media only screen and (max-width: $bp-medium) {
        width: auto;
      }

      @media only screen and (max-width: $bp-small) {
        width: 100%;
        text-align: left;
      }
    }
  }
}
